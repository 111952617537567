<template>
  <div>
    <Top />
    <v-container style="max-width: 900px; padding-bottom: 69px">
      <v-row v-for="(item, index) in list" :key="index">
        <v-col cols="12">
          <v-card elevation="1" outlined tile>
            <v-card-title>
              <v-avatar size="30" color="black" class="mr-4">
                <img :src="avatar" alt="Avatar" srcset="" width="30" />
              </v-avatar>

              <router-link to="/account_another" style="color: #777 !important">
                <h4>nomedaconta0123</h4>
              </router-link>

              <v-spacer></v-spacer>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon size="30" v-bind="attrs" v-on="on"
                    >mdi-dots-horizontal</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item>
                    <v-list-item-title>Denunciar</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-card-title>
            <carousel perPage="1">
              <slide>
                <img :src="post" alt="" srcset="" width="100%" />
              </slide>
              <slide>
                <img :src="post" alt="" srcset="" width="100%" />
              </slide>
              <slide>
                <img :src="post" alt="" srcset="" width="100%" />
              </slide>
            </carousel>

            <v-card-title>
              <v-icon color="red" size="30">mdi-heart</v-icon>
              <v-icon size="30">mdi-comment-processing</v-icon>
              <v-icon size="30"> mdi-share-variant</v-icon>

              <v-spacer></v-spacer>
              <v-icon color="blue" size="30">mdi-bookmark</v-icon>
            </v-card-title>
            <v-card-subtitle
              class="d-flex justify-content-center align-items-center mt-4"
            >
              <v-avatar size="30" color="black" class="mr-4">
                <img :src="avatar" alt="Avatar" srcset="" width="30" />
              </v-avatar>
              <span
                >Curtido por <b> o_miranha2</b> e <b>outras 77 pessoas</b></span
              >
            </v-card-subtitle>
            <v-card-text class="text-left">
              <p><b>kaizinchan</b> Mais um heueue... <a href="">Mais</a></p>
              <p><b>kaizinchan</b> Mais um heueue... <a href="">Mais</a></p>
              <p>HÁ 10 MINUTOS</p>
            </v-card-text>
            <v-card-actions>
              <v-icon>mdi-emoticon</v-icon>
              <v-text-field></v-text-field>
              <a color="primary">Publicar</a>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
    <bottom-navigation></bottom-navigation>
  </div>
</template>
<script>
export default {
  data() {
    return {
      avatar: require("@/assets/avatar.jpg"),
      post: require("@/assets/post.jpg"),
      list: [1, 2, 3, 4, 5, 6, 7, 7, 8],
    };
  },
};
</script>